import React from 'react'
import { FidgetSpinner } from 'react-loader-spinner'

const FidgetSpinnerLoader = () => (
  <div className="wheels">
    <FidgetSpinner
      height="11"
      width="11"
      radius="9"
      backgroundColor="#000"
      wrapperStyle={{ width: 'fit-content', alignItems: 'start', margin: '-19px 0 0 0' }}
      wrapperClassName=""
      visible={true}
    />
    <FidgetSpinner
      height="11"
      width="11"
      radius="9"
      backgroundColor="#000"
      wrapperStyle={{ width: 'fit-content', alignItems: 'end', margin: '-19px 0 0 0' }}
      wrapperClassName=""
      visible={true}
    />
  </div>
)

export default React.memo(FidgetSpinnerLoader)
