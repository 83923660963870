import { uniqueByKey } from 'src/utils/helpers'
import {
  GET_BROKERS_PRICING_START,
  GET_BROKERS_PRICING_SUCCESS,
  GET_BROKERS_PRICING_FAIL,
  UPDATE_BROKER_PRICING_START,
  UPDATE_BROKER_PRICING_SUCCESS,
  UPDATE_BROKER_PRICING_FAIL,
} from '../constants/index'

const initialState = {
  brokersPricingList: {
    isLoading: false,
    msg: '',
    data: [],
    errors: [],
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // BROKER PRICING
    case GET_BROKERS_PRICING_START:
      return {
        ...state,
        brokersPricingList: {
          ...state.brokersPricingList,
          msg: 'Loading! Please wait...',
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_BROKERS_PRICING_SUCCESS:
      return {
        ...state,
        brokersPricingList: {
          data: uniqueByKey({
            arr: [...state?.brokersPricingList?.data, ...action?.payload],
            key: 'broker',
          }),
          success: true,
          isLoading: false,
          errors: [],
        },
      }
    case GET_BROKERS_PRICING_FAIL:
      return {
        ...state,
        brokersPricingList: {
          ...state.brokersPricingList,
          isLoading: false,
          errors: action?.payload,
        },
      }

    case UPDATE_BROKER_PRICING_START:
      return {
        ...state,
        updatedBrokerPricing: {
          msg: 'Updating! Please wait ...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case UPDATE_BROKER_PRICING_SUCCESS:
      const filteredBrokersPricing = state.brokersPricingList?.data?.filter(
        (d) => d?.broker !== action?.payload?.brokerPricing?.broker,
      )
      return {
        ...state,
        updatedBrokerPricing: {
          ...action?.payload,
          success: true,
          isLoading: false,
        },
        brokersPricingList: {
          data: [...filteredBrokersPricing, action?.payload?.brokerPricing],
          success: true,
          isLoading: false,
        },
      }
    case UPDATE_BROKER_PRICING_FAIL:
      return {
        ...state,
        updatedBrokerPricing: {
          ...state.updatedBrokerPricing,
          isLoading: false,
          errors: action?.payload,
        },
      }

    default:
      return state
  }
}

export default reducer
