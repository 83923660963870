import { getRouteData } from 'src/actions/userActions'
import handleIDB from './handle-indexed-db'
import isEmpty from './is-empty'
import handleErr from './handle-error'

const gmapRoute = async ({ coord1, coord2, model, _id }) => {
  try {
    const track = {}
    const { routeData, success } = (await getRouteData(coord1?.join(), coord2?.join())) || {}

    track.code = success ? 'Ok' : ''
    track.routes = !isEmpty(routeData)
      ? [
          {
            distance: routeData.routes[0]?.distanceMeters,
            duration: Number(routeData.routes[0]?.duration?.replace('s', '')),
            geometry: routeData.routes[0]?.polyline?.encodedPolyline,
          },
        ]
      : []
    if (track?.code === 'Ok') {
      handleIDB.put({
        model,
        data: {
          _id,
          ...track,
        },
      })
    }
    return track.routes?.length ? track : ''
  } catch (err) {
    handleErr({ err })
    return ''
  }
}

const getTrack = (coord1, coord2, delay = 77) =>
  new Promise(async (resolve) => {
    try {
      const trackUrl = '//router.project-osrm.org/route/v1/driving/'
      const model = 'Track'
      const lng1 = coord1[0]
      const lat1 = coord1[1]
      const lng2 = coord2[0]
      const lat2 = coord2[1]
      // console.log(lat1, lat2, lng1, lng2)
      if (
        lat1 &&
        lng1 &&
        lat2 &&
        lng2 &&
        Number(lat1) !== -1000 &&
        Number(lng1) !== -1000 &&
        Number(lat2) !== -1000 &&
        Number(lng2) !== -1000
      ) {
        // console.log(lat1, lat2, lng1, lng2)
        const _id = `${coord1?.join()}${coord2?.join()}`

        const trackFromIDB = await handleIDB.getVal({ model, _id })
        if (trackFromIDB) return resolve(trackFromIDB)

        setTimeout(async () => {
          try {
            const res = await fetch(`${trackUrl}${coord1?.join()};${coord2?.join()}`)
            // const res = await fetch(`${trackUrl}${coord1?.join()};${coord2?.join()}?overview=false`, {
            //   mode: 'no-cors',
            // })
            const getShortesTrack = await res.json()
            if (getShortesTrack?.code === 'Ok') {
              if (getShortesTrack?.routes?.length) {
                // console.log(getShortesTrack)
                handleIDB.put({
                  model,
                  data: {
                    _id,
                    ...getShortesTrack,
                  },
                })
              }
              return resolve(getShortesTrack)
            } else {
              return resolve(await gmapRoute({ coord1, coord2, model, _id }))
            }
          } catch (err) {
            handleErr({ err })
            return resolve(await gmapRoute({ coord1, coord2, model, _id }))
          }
        }, delay)
      } else {
        resolve('')
      }
    } catch (err) {
      handleErr({ err })
      resolve('')
    }
  })

export default getTrack
