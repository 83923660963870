import { uniqueById } from 'src/utils/helpers'
import {
  GET_INSURANCES_START,
  GET_INSURANCES_SUCCESS,
  GET_INSURANCES_FAIL,
  CREATE_INSURANCE_RESET,
  CREATE_INSURANCE_START,
  CREATE_INSURANCE_SUCCESS,
  CREATE_INSURANCE_FAIL,
  UPDATE_INSURANCE_RESET,
  UPDATE_INSURANCE_START,
  UPDATE_INSURANCE_SUCCESS,
  UPDATE_INSURANCE_FAIL,
  DEACTIVATE_INSURANCE_START,
  DEACTIVATE_INSURANCE_SUCCESS,
  DEACTIVATE_INSURANCE_FAIL,
  DELETE_INSURANCE_START,
  DELETE_INSURANCE_SUCCESS,
  DELETE_INSURANCE_FAIL,
} from '../constants/index'

const initialState = {
  insurancesList: {
    isLoading: false,
    data: [], // insurances,
    errors: [],
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // GET INSURANCES

    case GET_INSURANCES_START:
      return {
        ...state,
        insurancesList: {
          ...state.insurancesList,
          msg: 'Loading! Please wait...',
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_INSURANCES_SUCCESS:
      return {
        ...state,
        insurancesList: {
          data: uniqueById([...state?.insurancesList?.data, ...action?.payload]),
          success: true,
          isLoading: false,
          errors: [],
        },
      }
    case GET_INSURANCES_FAIL:
      return {
        ...state,
        insurancesList: {
          ...state.insurancesList,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // CREATE INSURANCE

    case CREATE_INSURANCE_RESET:
      return {
        ...state,
        createdInsurance: {
          isLoading: false,
          errors: [],
          data: {},
          msg: '',
        },
      }
    case CREATE_INSURANCE_START:
      return {
        ...state,
        createdInsurance: {
          ...state.createdInsurance,
          msg: 'Creating ...',
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case CREATE_INSURANCE_SUCCESS:
      const insurancesData = state.insurancesList?.data?.filter(
        (b) => b?._id !== action?.payload?.insurance?._id,
      )
      return {
        ...state,
        createdInsurance: {
          ...action.payload,
          success: true,
          isLoading: false,
        },
        insurancesList: {
          ...state.insurancesList,
          data: [...insurancesData, action?.payload?.insurance],
          success: true,
          isLoading: false,
        },
      }
    case CREATE_INSURANCE_FAIL:
      return {
        ...state,
        insurancesList: {
          ...state.insurancesList,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // UPDATE INSURANCE

    case UPDATE_INSURANCE_RESET:
      return {
        ...state,
        updatedInsurance: {
          msg: '',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
        deactivatedInsurance: {
          msg: '',
          data: {},
          success: false,
          isLoading: false,
          errors: [],
        },
      }
    case UPDATE_INSURANCE_START:
      return {
        ...state,
        updatedInsurance: {
          msg: 'Updating! Please wait ...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case UPDATE_INSURANCE_SUCCESS:
      const filteredInsurances = state.insurancesList.data.filter(
        (d) => d?._id !== action?.payload?.insurance?._id,
      )
      return {
        ...state,
        updatedInsurance: {
          ...action?.payload,
          success: true,
          isLoading: false,
        },
        insurancesList: {
          data: [...filteredInsurances, action?.payload?.insurance],
          success: true,
          isLoading: false,
        },
      }
    case UPDATE_INSURANCE_FAIL:
      return {
        ...state,
        updatedInsurance: {
          ...state.updatedInsurance,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // DEACTIVATE INSURANCE

    case DEACTIVATE_INSURANCE_START:
      return {
        ...state,
        deactivatedInsurance: {
          success: false,
          isLoading: true,
          errors: [],
          msg: 'Deactivating destination...',
        },
      }
    case DEACTIVATE_INSURANCE_SUCCESS:
      const activeInsurances = state.insurancesList.data.filter(
        (d) => d._id !== action?.payload?.insurance?._id,
      )
      return {
        ...state,
        deactivatedInsurance: {
          ...action.payload,
          success: true,
          isLoading: false,
        },
        insurancesList: {
          data: [...activeInsurances, action.payload.insurance],
          success: true,
          isLoading: false,
        },
      }
    case DEACTIVATE_INSURANCE_FAIL:
      return {
        ...state,
        deactivatedInsurance: { ...state.payload, isLoading: false, errors: action?.payload },
      }

    // DELETE INSURANCE

    case DELETE_INSURANCE_START:
      return {
        ...state,
        deletedInsurance: {
          msg: 'Deleting! Please wait...',
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case DELETE_INSURANCE_SUCCESS:
      const remainingInsurances = state.insurancesList.data.filter(
        (d) => d._id !== action?.payload?.insurance?._id,
      )
      return {
        ...state,
        deletedInsurance: {
          ...action?.payload,
          success: true,
          isLoading: false,
        },
        insurancesList: {
          data: remainingInsurances,
          success: true,
          isLoading: false,
        },
      }
    case DELETE_INSURANCE_FAIL:
      return {
        ...state,
        deletedInsurance: {
          ...state.deletedInsurance,
          isLoading: false,
          errors: action?.payload,
        },
      }

    default:
      return state
  }
}

export default reducer
