import {
  UPDATE_SEARCHTERMS,
  UPDATE_USER_SETTINGS,
  GET_QUICKNOTES,
  UPDATE_QUICKNOTES,
  DELETE_QUICKNOTES,
  CHANGE_TRIP_STATUS_START,
  CHANGE_TRIP_STATUS_SUCCESS,
  CHANGE_TRIP_STATUS_FAIL,
  PUSH_DRIVER_LOC_START,
  PUSH_DRIVER_LOC_SUCCESS,
  PUSH_DRIVER_LOC_FAIL,
  USER_RESET_PWD_START,
  USER_RESET_PWD_SUCCESS,
  USER_RESET_PWD_FAIL,
  USER_CHANGE_PWD_START,
  USER_CHANGE_PWD_SUCCESS,
  USER_CHANGE_PWD_FAIL,
} from '../constants/index'
import handleIDB from 'src/utils/handle-indexed-db'
import userReducerInitialState from './userReducerInitialState'

const reducer = (state = userReducerInitialState, action) => {
  console.log(action.type, action.payload)
  switch (action.type) {
    case UPDATE_SEARCHTERMS:
      const searchTerms = {
        ...state.searchTerms,
        ...action.payload,
      }
      handleIDB?.put({
        model: 'Searchterms',
        _id: 'searchTerms',
        data: {
          constraint: searchTerms.constraint,
          constraintReport: searchTerms.constraintReport,
          productsLogsConstraint: searchTerms.productsLogsConstraint,
          tripState: searchTerms.tripState,
          driver: searchTerms.driver,
          customer: searchTerms.customer,
          destination: searchTerms.destination,
          page: searchTerms.page,
        },
      })
      return {
        ...state,
        searchTerms,
      }
    case UPDATE_USER_SETTINGS:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          ...action.payload,
        },
      }
    case GET_QUICKNOTES:
      return {
        ...state,
        quickNotes: {
          data: action.payload,
          isLoading: false,
          success: true,
        },
      }
    case UPDATE_QUICKNOTES:
      const filteredQn = state.quickNotes.data.filter(
        (n) => n.content.toLowerCase() !== action?.payload?.newQn.content.trim().toLowerCase(),
      )
      return {
        ...state,
        quickNotes: {
          data: [action?.payload?.newQn, ...filteredQn],
          isLoading: false,
          success: true,
        },
      }
    case DELETE_QUICKNOTES:
      const remainingQns = state.quickNotes.data?.filter((n) => n !== action.payload)
      return {
        ...state,
        quickNotes: {
          data: remainingQns,
          isLoading: false,
          success: true,
        },
      }

    // CHANGE TRIP STATUS
    case CHANGE_TRIP_STATUS_START:
      return {
        ...state,
        changedStatusTrip: {
          msg: '',
          data: {},
          isLoading: true,
          errors: [],
        },
      }
    case CHANGE_TRIP_STATUS_SUCCESS:
      return {
        ...state,
        changedStatusTrip: {
          success: true,
          isLoading: false,
          data: action.payload,
          errors: [],
        },
      }
    case CHANGE_TRIP_STATUS_FAIL:
      return {
        ...state,
        changedStatusTrip: {
          ...state.changedStatusTrip,
          isLoading: false,
          errors: action.payload,
        },
      }

    // PUSH DRIVER LOCATION

    case PUSH_DRIVER_LOC_START:
      return {
        ...state,
        pushedLocTrip: {
          msg: '',
          data: {},
          isLoading: true,
          errors: [],
        },
      }
    case PUSH_DRIVER_LOC_SUCCESS:
      return {
        ...state,
        pushedLocTrip: {
          success: true,
          isLoading: false,
          data: action.payload,
          errors: [],
        },
      }
    case PUSH_DRIVER_LOC_FAIL:
      return {
        ...state,
        pushedLocTrip: {
          ...state.pushedLocTrip,
          isLoading: false,
          errors: action.payload,
        },
      }

    // PWD RESET

    case USER_RESET_PWD_START:
      return {
        ...state,
        resetPwd: {
          msg: 'Requesting! Please wait...',
          isLoading: true,
          errors: [],
        },
      }
    case USER_RESET_PWD_SUCCESS:
      return {
        ...state,
        resetPwd: {
          success: true,
          isLoading: false,
          msg: action?.payload?.msg,
          errors: [],
        },
      }
    case USER_RESET_PWD_FAIL:
      return {
        ...state,
        resetPwd: {
          ...state.resetPwd,
          msg: '',
          isLoading: false,
          errors: action?.payload,
        },
      }

    // PWD CHANGE

    case USER_CHANGE_PWD_START:
      return {
        ...state,
        changePwd: {
          msg: 'Requesting! Please wait...',
          isLoading: true,
          errors: [],
        },
      }
    case USER_CHANGE_PWD_SUCCESS:
      return {
        ...state,
        changePwd: {
          success: true,
          isLoading: false,
          msg: action?.payload?.msg,
          errors: [],
        },
      }
    case USER_CHANGE_PWD_FAIL:
      return {
        ...state,
        changePwd: {
          ...state.changePwd,
          msg: '',
          isLoading: false,
          errors: action?.payload,
        },
      }

    default:
      return state
  }
}

export default reducer
