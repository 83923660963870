import { TOGGLE_UNFOLDABLE, TOGGLE_SIDEBAR } from '../constants'

const initialState = {
  sidebarShow: true,
  unfoldable: true,
}

export const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      // console.log(state, action.payload)
      return {
        ...state,
        sidebarShow: action.payload?.sidebarShow,
      }
    case TOGGLE_UNFOLDABLE:
      return {
        ...state,
        unfoldable: action.payload?.unfoldable,
      }
    default:
      return state
  }
}
