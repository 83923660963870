import React from 'react'
import PropTypes from 'prop-types'
import CIcon from '@coreui/icons-react'
import { cilBusAlt, cilX } from '@coreui/icons'
import FidgetSpinnerLoader from './FidgetSpinnerLoader'
import { useSelector } from 'react-redux'

const CompanyLoader = ({ progressBar, customClassName, loadingMsg, showCancel, cb }) => {
  const { companyInfo } = useSelector((state) => state?.companyAuth)

  const cancelBtn = () => (
    <button
      className="jelly-btn grad-danger-white shadow-danger mt-5"
      aria-label="Close"
      onClick={(e) => {
        e?.preventDefault && e.preventDefault()
        cb()?.func && cb()?.func()
      }}
    >
      <CIcon icon={cilX} /> Cancel
    </button>
  )
  return companyInfo?.logoSrc ? (
    <div className={`companyLogo-container ${customClassName || ''}`}>
      <div className="companyLogoLoader">
        <img
          loading="lazy"
          src={companyInfo?.logoSrc}
          height={77}
          className={`companyLogo logoImgLoader heart-beat sidebar-brand-full`}
          alt="logo"
        />
      </div>
      {progressBar ? (
        <div className="progress">
          <div
            style={{ width: `${progressBar}%` }}
            className={`progress-bar bg-info`}
            role="progressbar"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {progressBar}%
          </div>
        </div>
      ) : null}

      <div className="fs-13 mt-1 text-center fw-bold">
        <p>{loadingMsg || 'Please Wait...'}</p>
      </div>
      {showCancel && cancelBtn()}
    </div>
  ) : (
    <div className="carloader-container">
      <div className="carloader">
        <CIcon icon={cilBusAlt} size={'3xl'} className="car" />
        <FidgetSpinnerLoader />
      </div>
      {progressBar ? (
        <div className="progress">
          <div
            style={{ width: `${progressBar}%` }}
            className={`progress-bar bg-info`}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {progressBar}%
          </div>
        </div>
      ) : null}
      {showCancel && cancelBtn()}
    </div>
  )
}

CompanyLoader.propTypes = {
  customClassName: PropTypes.string,
  loadingMsg: PropTypes.string,
  progressBar: PropTypes.any,
  cb: PropTypes.any,
  showCancel: PropTypes.bool,
}

export default React.memo(CompanyLoader)
