export const defaultBrokerPricing = {
  delivery: 15,
  deliveryExtramile: 1.5,
  sedan: 10,
  sedanExtramile: 1.2,
  minivan: 17,
  minivanExtramile: 1.3,
  van: 27,
  vanExtramile: 1.5,
  bus: 37,
  busExtramile: 1.7,
  standard: 25,
  standardExtramile: 1.5,
  wheelchair: 45,
  wheelchairExtramile: 1.5,
  bariatricWheelchair: 65,
  bariatricWheelchairExtramile: 1.5,
  stretcher: 150,
  stretcherExtramile: 2,
  bariatricStretcher: 170,
  bariatricStretcherExtramile: 2,
  milesIncluded: 10,
}
