const reSizeBase64 = ({ srcBase64, size, imgType, quality }) =>
  new Promise((resolve) => {
    const img = new Image() //create a image
    img.src = srcBase64
    img.onload = function (el) {
      const canvas = document.createElement('canvas') //create a canvas
      canvas.width = size
      canvas.height = size

      //draw in canvas
      const ctx = canvas.getContext('2d')
      ctx.drawImage(el.target, 0, 0, canvas.width, canvas.height)

      //get the base64-encoded Data URI from the resize image
      const resizedSrcBase64 = ctx.canvas.toDataURL(`image/${imgType || 'png'}`, quality || 1)
      resolve(resizedSrcBase64)
    }
  })

const getBase64Url = ({
  imageFile,
  imageBase64Src,
  size,
  getImgFile,
  imgType,
  quality,
  aspectRatio = 1, // default square
}) =>
  new Promise((resolve) => {
    try {
      // const image = event.target.files[0]
      if (imageBase64Src) {
        const img = new Image() //create a image
        img.onload = function (el) {
          const canvas = document.createElement('canvas') //create a canvas
          const imgWidth = img.naturalWidth // size || img.naturalWidth
          const imgHeight = img.naturalHeight //size || img.naturalHeight
          canvas.width = imgWidth
          canvas.height = imgHeight

          // get the aspect ratio of the input image
          const imgAspectRatio = imgWidth / imgHeight
          // const biggerDimention = imgWidth > imgHeight ? imgWidth : imgHeight
          // if it's bigger than our target aspect ratio
          // let outputWidth = size
          // let outputHeight = inputHeight
          if (imgAspectRatio > aspectRatio) {
            canvas.width = canvas.height * aspectRatio
          } else if (imgAspectRatio < aspectRatio) {
            canvas.height = canvas.width / aspectRatio
          }

          // calculate the position to draw the image at
          const outputX = (canvas.width - imgWidth) * 0.5
          const outputY = (canvas.height - imgHeight) * 0.5

          //draw in canvas
          const ctx = canvas.getContext('2d')
          ctx.drawImage(el.target, outputX, outputY, imgWidth, imgHeight)

          //get the base64-encoded Data URI from the resize image
          const aspectRatioSrcBase64 = ctx.canvas.toDataURL(
            `image/${imgType || 'png'}`,
            quality || 1,
          )
          const resized = reSizeBase64({
            srcBase64: aspectRatioSrcBase64,
            size,
            imgType,
            quality,
          })
          resolve(resized)
        }
        img.src = imageBase64Src // start loading our image
      } else {
        const reader = new FileReader()

        //image turned to base64-encoded Data URI.
        reader.readAsDataURL(imageFile)
        // reader.name = image.name //get the image's name
        reader.size = imageFile.size //get the image's size
        reader.onload = (event) => {
          const img = new Image() //create a image
          img.onload = function (el) {
            const canvas = document.createElement('canvas') //create a canvas
            const imgWidth = img.naturalWidth // size || img.naturalWidth
            const imgHeight = img.naturalHeight //size || img.naturalHeight
            canvas.width = imgWidth
            canvas.height = imgHeight

            // get the aspect ratio of the input image
            const imgAspectRatio = imgWidth / imgHeight
            // const biggerDimention = imgWidth > imgHeight ? imgWidth : imgHeight
            // if it's bigger than our target aspect ratio
            // let outputWidth = size
            // let outputHeight = inputHeight
            if (imgAspectRatio > aspectRatio) {
              canvas.width = canvas.height * aspectRatio
            } else if (imgAspectRatio < aspectRatio) {
              canvas.height = canvas.width / aspectRatio
            }

            // calculate the position to draw the image at
            const outputX = (canvas.width - imgWidth) * 0.5
            const outputY = (canvas.height - imgHeight) * 0.5

            //draw in canvas
            const ctx = canvas.getContext('2d')
            ctx.drawImage(el.target, outputX, outputY, imgWidth, imgHeight)

            //get the base64-encoded Data URI from the resize image
            const aspectRatioSrcBase64 = ctx.canvas.toDataURL(
              `image/${imgType || 'png'}`,
              quality || 1,
            )
            const resized = reSizeBase64({
              srcBase64: aspectRatioSrcBase64,
              size,
              imgType,
              quality,
            })
            if (getImgFile) {
              img.src = resized
              resolve({
                imageFileResized: img,
                srcBase64: resized,
              })
            } else {
              resolve(resized)
            }
            /*Now you can send "srcEncoded" to the server and
  convert it to a png o jpg. Also can send
  "el.target.name" that is the file's name.*/
            // companyLogo.src = srcBase64
            // setFormState({ ...formState, logoSrc: srcBase64 })
          }
          img.src = event.target.result //result is base64-encoded Data URI
        }
      }
    } catch (error) {
      resolve('')
    }
  })

export default getBase64Url
