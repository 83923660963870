import React from 'react'
import PropTypes from 'prop-types'
import logo from '../assets/logo/xperdriverlogo.png'

const LogoBig = (props) => (
  <div className="text-center justify-content-center mb-5">
    <img
      loading="lazy"
      src={props.src || logo}
      height={77}
      className="sidebar-brand-full m-3"
      alt="XPERDRIVER App"
    />
    <h1 className="fw-bolder fs-4 px-3 text-uppercase"> {props.name}</h1>
  </div>
)

LogoBig.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
}
export default React.memo(LogoBig)
