import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { List, arrayMove } from 'react-movable'
import getTrack from 'src/utils/get-track'
// import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { CCard, CCardBody, CFormSelect } from '@coreui/react'
import {
  cilUser,
  cilGroup,
  cilLocationPin,
  cilClock,
  cilPlus,
  cilSearch,
  cilXCircle,
  cilMenu,
  cilArrowThickRight,
  cilLoopCircular,
  cilFlagAlt,
  cilBullhorn,
  cilCarAlt,
  cilBusAlt,
  cibShopify,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import handleErr from 'src/utils/handle-error'
import { metersToMiles } from 'src/utils/get-miles'
import Capitalize from 'src/utils/capitalize'
import OlMap from '../widget/OlMap'
import { formatToHTMLinput, minutesToTimes } from 'src/utils/handle-date'
import { getAddressSuggestions, getPlaceDetails, sendEmailAction } from 'src/actions/userActions'
import { slowDown, validateEmail, validatePhone, validCoords, areEquals } from 'src/utils/helpers'
import isEmpty from 'src/utils/is-empty'
import toggleModal from 'src/components/toggle-modal'
import vehicleMaxCapacity from 'src/utils/vehicle-max-capacity'
import vehicleTypes from 'src/utils/vehicle-types'
import LogoBig from 'src/components/LogoBig'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import geoLocation from 'src/utils/geoLocation'
import formatPhone from 'src/utils/format-phone'

const Booking = ({ brokerPricing, vehicleType, companyData }) => {
  const dispatch = useDispatch()
  // const [startAddress, setStartAddress] = useState('')
  // const [endAddress, setEndAddress] = useState('')
  // const [startCoords, setStartCoords] = useState(null)
  // const [endCoords, setEndCoords] = useState(null)
  const [distance, setDistance] = useState(0)
  const [duration, setDuration] = useState(0)
  const [showMap, setShowMap] = useState(null)
  const [price, setPrice] = useState(null)
  const [vehicleTypeState, setVehicleTypeState] = useState('sedan')
  const [riderCount, setRiderCount] = useState(1)
  const [coordsCount, setCoordsCount] = useState(1)
  const [addresses, setAddresses] = useState([])
  const [addressIdx, setAddressIdx] = useState([])
  const [coords, setCoords] = useState([])
  const [myLocationCoords, setMyLocationCoords] = useState()
  const [isCalculating, setIsCalculating] = useState(false)
  const [dateTime, setDateTime] = useState({})
  const [isSearching, setIsSearching] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [showModal, setShowModal] = useState(null)
  const [tripMode, setTripMode] = useState('roundtrip')
  const [tripType, setTripType] = useState('ride')
  const [showMyLocationButton, setShowMyLocationButton] = useState(false)

  const locationDifferentFromMyLocation = ({ coords, myLocationCoords }) => {
    return (
      !myLocationCoords?.length ||
      !coords[0] ||
      !coords[1] ||
      (coords[0] && !areEquals(myLocationCoords, coords[0])) ||
      (coords[1] && !areEquals(myLocationCoords, coords[1]))
    )
  }

  const getClientCoords = async () => {
    try {
      const clientCoords = await geoLocation()
      console.log(clientCoords)
      if (!validCoords({ coords: clientCoords })) return
      const myLocationCoords = [clientCoords.lng, clientCoords.lat]
      setMyLocationCoords(myLocationCoords)
      console.log(coords[0])
      console.log(coords[1])
      console.log(myLocationCoords)

      if (locationDifferentFromMyLocation({ coords, myLocationCoords })) {
        const newAddresses = ['My Location', ...addresses.filter((a) => a)]
        setAddressIdx(0)
        setAddresses(newAddresses)
        setCoords([myLocationCoords, ...coords])
        console.log(addresses, newAddresses, newAddresses[coordsCount - 1], coordsCount)
        if (coordsCount < 2 && newAddresses[coordsCount - 1]) {
          setCoordsCount((x) => x + 1)
        }
      }
    } catch (err) {
      handleErr({ err })
    }
  }

  useEffect(() => {
    getClientCoords()
  }, [])

  const handleDateTimeChange = (e) => {
    const { name, value } = e.target
    setDateTime((prev) => ({ ...prev, [name]: value }))
  }

  const calculateFare = (basePrice, distance, riderCount) => {
    return (1.2 * distance * riderCount + basePrice).toFixed(2)
  }

  // const handleOnChageAddress = async ({ value, i }) => {
  //   try {
  //     const addressesCopy = [...addresses]
  //     addressesCopy[i] = value
  //     setAddresses(addressesCopy)
  //   } catch (error) {
  //     console.error('Error:', error)
  //   }
  // }

  const handleSearch = async ({ fullAddress, i }) => {
    try {
      const addressesCopy = [...addresses]
      addressesCopy[i] = fullAddress
      setAddresses(addressesCopy)
      setAddressIdx(i)
      setIsSearching(true)
      await slowDown(1707)
      if (!fullAddress) {
        setIsSearching(false)
        setSuggestions([])
        addressesCopy.splice(i, 1)
        setAddresses(addressesCopy)
        const coordsCopy = [...coords]
        coordsCopy.splice(i, 1)
        setCoords(coordsCopy)
        if (i === 0 && locationDifferentFromMyLocation({ coords: coordsCopy, myLocationCoords })) {
          setShowMyLocationButton(true)
        }
        return
      }
      const { data } = (await getAddressSuggestions({ fullAddress })) || {}
      setIsSearching(false)
      if (isEmpty(data)) return
      setSuggestions(data?.predictions?.length ? data?.predictions?.slice(0, 6) : []) // Get top 6 suggestions
    } catch (error) {
      console.error('Error fetching address suggestions:', error)
    }
  }

  const handleSelect = async ({ e, suggestion }) => {
    try {
      e.preventDefault && e.preventDefault()
      setIsSearching(true)
      console.log(suggestion)
      setSuggestions([])
      const addressesCopy = [...addresses]
      addressesCopy[addressIdx] = suggestion?.description
      setAddresses(addressesCopy)

      const { data } = (await getPlaceDetails({ placeId: suggestion.place_id })) || {}

      if (isEmpty(data) || data?.status !== 'OK') {
        await slowDown(707)
        setIsSearching(false)
        return
      }

      const coordsCopy = [...coords]
      coordsCopy[addressIdx] = [
        data.result.geometry.location.lng,
        data.result.geometry.location.lat,
      ]
      setCoords(coordsCopy)
      if (coordsCount <= 1) setCoordsCount(2)
      setIsSearching(false)
      // setCoords([latLng.lng, latLng.lat]) // Setting coordinates in [lng, lat] format
    } catch (error) {
      console.error('Error: ', error)
    }
  }

  useEffect(() => {
    if (riderCount > vehicleMaxCapacity[vehicleTypeState]) {
      const suitableVehicle = vehicleTypes.find((v) => riderCount <= vehicleMaxCapacity[v])
      if (suitableVehicle) {
        setVehicleTypeState(suitableVehicle.vehicleType)
      }
    }
  }, [riderCount])

  useEffect(() => {
    if (riderCount > vehicleMaxCapacity[vehicleTypeState]) {
      setRiderCount(vehicleMaxCapacity[vehicleTypeState])
    }
  }, [vehicleTypeState])

  const calculateDurationAndDistance = async (coords) => {
    try {
      setDistance(0)
      setDuration(0)

      if (coords.length < 2) {
        setSuggestions([])
        return
      }
      // const point1 = [-87.75136608077267, 41.92429003497408]
      // const point2 = [-87.76125806730694, 41.92406652295526]
      setIsCalculating(true)

      coords.forEach(async (coord, i, arr) => {
        const coord2 = arr[i + 1]
        console.log(coord, coord2, areEquals(coord, coord2))
        if (!coord2 || areEquals(coord, coord2)) {
          setIsCalculating(false)
          return false
        }
        const track = (await getTrack(coord, coord2)) || {}
        if (isEmpty(track) || isEmpty(track?.routes)) return
        const distanceInMiles = metersToMiles(track?.routes[0]?.distance)
        const durationTime = Number(track?.routes[0]?.duration)
        // const distanceInMiles = track.routes[0].distance / 1609
        // setDuration(durationTime)
        setDuration((duration) => duration + durationTime)
        setDistance((distance) => distance + distanceInMiles)
        if (
          !dateTime?.expectedPickupTime1 ||
          new Date(dateTime?.expectedPickupTime1) < new Date()
        ) {
          setDateTime((prev) => ({
            ...prev,
            expectedPickupTime1: formatToHTMLinput({}),
          }))
        }
        console.log(i >= arr.length - 1)
        if (i >= arr.length - 1) setIsCalculating(false)
      })

      // setIsCalculating(false)
    } catch (err) {
      setIsCalculating(false)
      handleErr({ err })
    }
  }

  useEffect(() => {
    try {
      if (isEmpty(brokerPricing)) return
      if (distance <= 0 || coords.length < 2) {
        setPrice(0)
        return
      }
      const calculatedPrice = calculateFare(brokerPricing[vehicleTypeState], distance, riderCount)
      setPrice(calculatedPrice)
    } catch (err) {
      handleErr({ err })
    }
  }, [brokerPricing, coords.length, distance, riderCount, vehicleTypeState])

  useEffect(() => {
    calculateDurationAndDistance(coords)
  }, [coords])

  useEffect(() => {
    try {
      if (coords.length > 1) {
        const coordsModif = coords.map((coord, i, arr) => {
          return {
            id: `${coord[0]}-${coord[1]}`,
            coordType: i === 0 ? 'Customer' : 'Destination',
            tripType: 'ride',
            name: addresses[i],
            location: { lng: coord[0], lat: coord[1] },
            startRoute: true,
            driver: 'x', // i === arr.length - 1 ? 'x' : '',
          }
        })
        setShowMap(<OlMap rounded={true} autoPan={true} coords={coordsModif} height={`303px`} />)
      } else {
        setShowMap(null)
      }
    } catch (err) {
      handleErr({ err })
    }
  }, [coords])

  const openRequestModal = ({
    price,
    distance,
    duration,
    coords,
    addresses,
    tripMode,
    tripType,
    riderCount,
    vehicleTypeState,
    dateTime,
  }) => {
    setShowModal(
      toggleModal({
        setShowModal,
        modalTitle: 'Personal Information',
        modalVisible: true,
        modalType: 'filterTripByDateModal',
        modalId: 'filterTripByDateModal1',
        // noHeader: true,
        modalBody: (
          <>
            <ul className="list-group p-2 mw303">
              <li className="d-flex my-3">
                <input
                  name="firstName"
                  placeholder="First Name"
                  className="text-capitalize simple-field w-100"
                />
              </li>
              <li className="d-flex my-3">
                <input
                  name="lastName"
                  placeholder="Last Name"
                  className="text-capitalize simple-field w-100"
                />
              </li>
              <li className="d-flex my-3">
                <input
                  name="phone"
                  placeholder="Phone"
                  className="simple-field w-100"
                  // value={formatPhone(document.getElementsByName('email')[0]?.value?.trim())}
                  onChange={(e) => {
                    document.getElementsByName('phone')[0].value = formatPhone(
                      e.target.value?.trim(),
                    )
                  }}
                />
              </li>
              <li className="d-flex my-3">
                <input name="email" placeholder="Email" className="simple-field w-100" />
                <input
                  name="emailVerify"
                  placeholder="verify Email"
                  className="simple-field w-100"
                />
              </li>

              {/* <hr /> */}
              <li className="w-100">
                <button
                  className="jelly-btn grad-success-white"
                  onClick={(e) => {
                    e.preventDefault()
                    const firstName = Capitalize(document.getElementsByName('firstName')[0]?.value)
                    const lastName = Capitalize(document.getElementsByName('lastName')[0]?.value)
                    const email = document.getElementsByName('email')[0]?.value?.trim()
                    const emailVerify = document.getElementsByName('emailVerify')[0]?.value?.trim()
                    const phone = document.getElementsByName('phone')[0]?.value?.trim()

                    if (!firstName || firstName < 3)
                      return toast.error(`First Name is missing or too Short`)
                    if (!lastName || lastName < 2)
                      return toast.error(`Last Name is missing or too Short`)
                    if (!phone) return toast.error(`Phone is missing`)
                    if (!validatePhone(phone)) return toast.error(`Phone is invalid`)
                    if (!email) return toast.error(`Email is missing`)
                    if (!validateEmail(email)) return toast.error(`Email format is invalid`)
                    if (!emailVerify) return toast.error(`Please Enter Verify Email`)
                    if (email.toLowerCase() !== emailVerify.toLowerCase())
                      return toast.error(`Email mismatch`)

                    const formData = {
                      firstName,
                      lastName,
                      email: email.trim()?.toLowerCase(),
                      phone,
                      price,
                      distance,
                      duration,
                      coords,
                      addresses,
                      tripMode,
                      tripType,
                      riderCount,
                      vehicleType: vehicleTypeState,
                      dateTime,
                    }
                    dispatch(
                      sendEmailAction({
                        cId: companyData?._id,
                        isPublic: true,
                        isBooking: true,
                        successMsg: `${Capitalize(tripType)} Request Sent!`,
                        formData,
                        to: `contact@xperdriver.com`,
                        subject: `New ${Capitalize(tripType)} Request`,
                        bodyContent: `
                         ${firstName} ${lastName},
                         email: ${email},
                         Phone: ${phone},
                         Price: ${price},
                         vehicle: ${vehicleTypeState},
                         Passengers: ${riderCount},
                         ${tripMode},
                         distance: ${distance}mi,
                         Duration: ${duration}min,
                         Pickup Time: ${dateTime?.expectedPickupTime1},
                         Return Time: ${dateTime?.expectedPickupTime2},
                         `,
                        // fileType: 'pdf',
                        // fileBase64: `${fileBase64?.split('base64,')[1]}`,
                        // isInvoice: true,
                        // fileName: `${opt.filename}.pdf`,
                        cb: (data) => {
                          setShowModal(toggleModal({ closeModal: true }))
                        },
                      }),
                    )
                  }}
                >
                  <CIcon icon={cilBullhorn} /> Request
                </button>
                {/* <button
                  className="jelly-btn grad-danger-white"
                  onClick={(e) => {
                    e.preventDefault()
                    setShowModal(toggleModal({ closeModal: true }))
                  }}
                >
                  <CIcon icon={cilXCircle} /> Cancel
                </button> */}
              </li>
            </ul>
          </>
        ),
      }),
    )
  }

  const removeEl = ({ e, i }) => {
    e.preventDefault()
    if (addresses[i]) {
      const addressesCopy = [...addresses]
      addressesCopy.splice(i, 1)
      setAddresses(addressesCopy)
    }

    if (coords[i]) {
      const coordsCopy = [...coords]
      coordsCopy.splice(i, 1)
      setCoords(coordsCopy)
    }

    if (coordsCount > 1) setCoordsCount((prev) => prev - 1)
  }

  return (
    <>
      {showModal}
      <div className="d-flex flex-column align-content-center align-items-center w-100 min-vh-100 p-1 grad-info">
        <LogoBig src={companyData?.logoSrc || ''} name={companyData?.name || 'XPERDRIVER'} />
        <CCard className="d-flex align-items-center m-1 vw-98 mw-555 mx-auto">
          {/* <CCardHeader>
        <h3>Distance & Price Calculator</h3>
      </CCardHeader> */}
          <CCardBody className="w-100 p-1">
            {showMap}
            {duration > 0 && (
              <div className="w-100 d-flex justify-content-center my-3">
                <div className="w-fit d-flex align-items-center fw-bold">
                  <CIcon size="lg" icon={cilLocationPin} className="mx-auto" />
                  {distance.toFixed(2)}mi
                </div>
                <div className="w-fit d-flex align-items-center ms-2 fw-bold">
                  <CIcon size="lg" icon={cilClock} className="mx-1" />
                  {minutesToTimes(duration)} min
                </div>
              </div>
            )}
            <div className="addresses-box">
              <List
                container={document.querySelector('.addresses-box')}
                transitionDuration={0} //ms 300 default
                lockVertically={true}
                values={[...Array(coordsCount).keys()].map((x, i, arr) => {
                  return (
                    <div key={i} className={`w-100`}>
                      {i === 0 && showMyLocationButton && (
                        <button
                          className="bg-black m-1 p-2 text-white fw-bold"
                          onClick={(e) => {
                            e.preventDefault()
                            getClientCoords()
                            setShowMyLocationButton(false)
                          }}
                        >
                          <CIcon icon={cilLocationPin} /> Set my Location
                        </button>
                      )}
                      {coords?.length > 1 ? (
                        i === 0 ? (
                          <div className="w-100">
                            <strong
                              className={`${coords?.length > 1 ? 'ms-5' : 'ms-2'} text-secondary`}
                            >
                              <CIcon icon={cilLocationPin} /> Pickup Address
                            </strong>
                          </div>
                        ) : coordsCount === i + 1 ? (
                          <div className="w-100 mt-2">
                            <strong
                              className={`${coords?.length > 1 ? 'ms-5' : 'ms-2'} text-secondary`}
                            >
                              <CIcon icon={cilFlagAlt} /> Dropoff Address
                            </strong>
                          </div>
                        ) : (
                          <div className="w-100 mt-2">
                            <strong
                              className={`${coords?.length > 1 ? 'ms-5' : 'ms-2'} text-secondary`}
                            >
                              <CIcon icon={cilFlagAlt} /> Stop {i}
                            </strong>
                          </div>
                        )
                      ) : null}

                      <div className={`w-100 d-flex align-items-center`}>
                        {coords?.length > 1 && (
                          <CIcon
                            size="xl"
                            className={`mx-1 bookingAddressDragger ${
                              !coords[i] ? `invisible` : ``
                            }`}
                            icon={cilMenu}
                          />
                        )}
                        <input
                          size=""
                          id={`addressBox${i}`}
                          placeholder="Where To ..."
                          className="address-box w-100 p-3 rounded shadow"
                          value={addresses[i] || ''}
                          // onChange={(e) => handleOnChageAddress({ value: e.target.value, i })}
                          onChange={(e) => {
                            handleSearch({ fullAddress: e.target.value, i })
                          }}
                          onFocus={(e) => {
                            e.preventDefault()
                            if (
                              locationDifferentFromMyLocation({ coords, myLocationCoords }) &&
                              +e.target.id.match(/\d+/)[0] === 0 &&
                              !addresses[0]?.trim()
                            ) {
                              setShowMyLocationButton(true)
                            }
                          }}
                        />
                        {coordsCount > 2 && (
                          <CIcon
                            role="button"
                            size="xl"
                            className="mx-1"
                            icon={cilXCircle}
                            onClick={(e) => removeEl({ e, i })}
                          />
                        )}
                      </div>
                    </div>
                  )
                })}
                // beforeDrag={() => { }}
                onChange={({ oldIndex, newIndex, targetRect }) => {
                  if (oldIndex !== newIndex) {
                    if (addresses[oldIndex]) {
                      const addressesCopy = [...addresses]
                      addressesCopy.splice(oldIndex, 1)
                      addressesCopy.splice(newIndex, 0, addresses[oldIndex])
                      setAddresses(addressesCopy)
                    }

                    if (coords[oldIndex]) {
                      const coordsCopy = [...coords]
                      coordsCopy.splice(oldIndex, 1)
                      coordsCopy.splice(newIndex, 0, coords[oldIndex])
                      setCoords(coordsCopy)
                    }
                  }
                }}
                renderList={({ children, props, isDragged }) => (
                  <div
                    style={{ padding: 0, cursor: isDragged ? 'grabbing' : undefined }}
                    {...props}
                  >
                    {children}
                  </div>
                )}
                renderItem={({ value, props, isDragged, isSelected, index }) => {
                  return (
                    <div {...props} key={index}>
                      {value}
                    </div>
                  )
                }}
              />
            </div>

            {isSearching ? (
              <div className="mt-1 w-100 text-center fw-bold text-secondary">Please Wait...</div>
            ) : suggestions?.length ? (
              <div className="google-suggestion-box">
                {suggestions
                  ?.filter((s) => s.description !== addresses[addresses?.length - 2])
                  .map((suggestion, i) => (
                    <div className="google-suggestion" key={i}>
                      <button className="" onClick={(e) => handleSelect({ e, suggestion })}>
                        <CIcon icon={cilSearch} className="me-1" />{' '}
                        <div className="d-flex align-items-center justify-content-start flex-column">
                          <div className="w-100 text-start">
                            {suggestion?.description?.split(',')?.slice(0, 2)?.join(',')}
                          </div>
                          {suggestion?.description
                            ?.split(',')
                            ?.slice(2, suggestion?.description?.split(',')?.length)?.length ? (
                            <div className="w-100 mt-n1 text-start">
                              {suggestion?.description
                                ?.split(',')
                                ?.slice(2, suggestion?.description?.split(',')?.length)
                                ?.join(',')}
                            </div>
                          ) : null}
                        </div>
                      </button>
                    </div>
                  ))}
              </div>
            ) : null}
            {coords.length > 1 && coords[coordsCount - 1] && (
              <button
                className="my-3 fw-bold bg-transparent text-secondary"
                onClick={async (e) => {
                  e.preventDefault()
                  const cCount = coordsCount + 1
                  setCoordsCount(cCount)
                  await slowDown(707)
                  document.getElementById(`addressBox${cCount - 1}`)?.focus()
                }}
              >
                <div className="d-inline-flex">
                  <CIcon role="button" size="xl" icon={cilPlus} className="me-1" />{' '}
                  <span>Add Destination</span>
                </div>
              </button>
            )}
            {coords.length > 1 ? (
              <>
                <div className="w-100 d-flex justify-content-center mt-5 mb-3">
                  <div className="w-fit shadow rounded d-flex align-items-center">
                    <div className="ms-2 mx-auto text-center">
                      <CIcon
                        size="lg"
                        icon={riderCount === 1 ? cilUser : cilGroup}
                        className="mx-auto"
                      />
                    </div>
                    <CFormSelect
                      className="ms-1 border-0 text-center fs-22"
                      value={riderCount}
                      onChange={(e) => setRiderCount(Number(e.target.value))}
                    >
                      {[...Array(77).keys()].map((i) => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </CFormSelect>
                  </div>

                  <div className="w-fit shadow rounded d-flex align-items-center">
                    <CFormSelect
                      className="ms-0 border-0 text-center"
                      value={vehicleTypeState}
                      onChange={(e) => setVehicleTypeState(e.target.value)}
                    >
                      {vehicleTypes.map((vType) => (
                        <option
                          key={vType}
                          value={vType}
                          // disabled={riderCount > vehicle.maxRiders}
                        >
                          {Capitalize(vType)}
                        </option>
                      ))}
                    </CFormSelect>
                  </div>
                </div>

                <div className="w-100 d-flex justify-content-center my-5">
                  <div className="jelly-box gap3">
                    <button
                      className={`jelly-btn ${
                        tripType === 'ride' ? `grad-success` : `grad-purple`
                      }`}
                      onClick={(e) => {
                        e.preventDefault()
                        const toggleTripType = tripType === 'ride' ? 'delivery' : 'ride'
                        setTripType(toggleTripType)
                        if (toggleTripType === 'delivery') {
                          setVehicleTypeState('sedan')
                          setTripMode('oneway')
                        }
                      }}
                    >
                      <CIcon
                        icon={
                          tripType === 'ride'
                            ? vehicleTypeState === 'sedan'
                              ? cilCarAlt
                              : cilBusAlt
                            : cibShopify
                        }
                      />{' '}
                      {Capitalize(tripType)}
                    </button>
                    {tripType === 'ride' && (
                      <>
                        <button
                          className={`jelly-btn ${tripMode === 'oneway' ? `grad-success` : ``}`}
                          onClick={(e) => {
                            e.preventDefault()
                            setTripMode('oneway')
                          }}
                        >
                          <CIcon icon={cilArrowThickRight} /> One Way
                        </button>
                        <button
                          className={`jelly-btn ${tripMode === 'roundtrip' ? `grad-success` : ``}`}
                          onClick={(e) => {
                            e.preventDefault()
                            setTripMode('roundtrip')
                          }}
                        >
                          <CIcon icon={cilLoopCircular} /> Round-trip
                        </button>
                      </>
                    )}
                  </div>
                </div>

                <div className="w-fit d-flex flex-column justify-content-center my-4 mx-auto">
                  <p className="mb-0 fw-bold">
                    <CIcon icon={cilClock} /> PICK UP TIME
                  </p>
                  <div className="p-3 rounded shadow bg-white">
                    <input
                      name="expectedPickupTime1"
                      type="datetime-local"
                      min={formatToHTMLinput({})}
                      defaultValue={dateTime?.expectedPickupTime1}
                      // defaultValue={dateTime?.expectedPickupTime1 || formatToHTMLinput({})}
                      // className="p-3 rounded shadow bg-white"
                      onChange={handleDateTimeChange}
                    />
                  </div>
                </div>

                {tripMode === 'roundtrip' && (
                  <div className="w-fit d-flex flex-column justify-content-center my-5 mx-auto">
                    <p className="mb-0 fw-bold">
                      <CIcon icon={cilClock} /> RETURN TIME
                    </p>
                    <div className="p-3 rounded shadow bg-white">
                      <input
                        name="expectedPickupTime2"
                        type="datetime-local"
                        min={dateTime?.expectedPickupTime1 || formatToHTMLinput({})}
                        defaultValue={dateTime?.expectedPickupTime2}
                        // defaultValue={dateTime?.expectedPickupTime2 || formatToHTMLinput({})}

                        onChange={handleDateTimeChange}
                      />
                    </div>
                  </div>
                )}
              </>
            ) : null}

            {isCalculating ? (
              <strong className="p-1 fw-bold text-secondary">Please Wait...</strong>
            ) : price > 0 && distance > 0 && duration ? (
              <button
                className="py-2 w-100 rounded shadow-hover bg-success text-white"
                onClick={(e) => {
                  e.preventDefault()
                  openRequestModal({
                    price,
                    distance,
                    duration: minutesToTimes(duration),
                    coords,
                    addresses,
                    tripMode,
                    tripType,
                    riderCount,
                    vehicleTypeState,
                    dateTime,
                  })
                }}
              >
                Done <div className="fs-22 fw-bold">${price}</div>
              </button>
            ) : null}
          </CCardBody>
        </CCard>
      </div>
    </>
  )
}

// Define the expected prop types
Booking.propTypes = {
  brokerPricing: PropTypes.any,
  companyData: PropTypes.any,
  // brokerPricing: PropTypes.shape({
  //   pricePerMile: PropTypes.number,
  // }),
  vehicleType: PropTypes.string,
}

export default Booking
