import {
  FIND_ABSENSE_START,
  FIND_ABSENSE_SUCCESS,
  FIND_ABSENSE_FAIL,
  CREATE_ABSENSE_START,
  CREATE_ABSENSE_SUCCESS,
  CREATE_ABSENSE_FAIL,
  UPDATE_ABSENSE_START,
  UPDATE_ABSENSE_SUCCESS,
  UPDATE_ABSENSE_FAIL,
} from '../constants/index'

const initialState = {
  absenseInfo: {
    isLoading: false,
    msg: '',
    data: [],
    errors: [],
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // FIND ABSENSE
    case FIND_ABSENSE_START:
      return {
        ...state,
        absenseInfo: {
          ...state.absenseInfo,
          isLoading: true,
        },
      }
    case FIND_ABSENSE_SUCCESS:
      return {
        ...state,
        absenseInfo: {
          success: true,
          isLoading: false,
          data: action.payload,
          errors: [],
        },
      }
    case FIND_ABSENSE_FAIL:
      return {
        ...state,
        absenseInfo: {
          ...state.absenseInfo,
          isLoading: false,
          errors: action.payload,
        },
      }
    // CREATE ABSENSE
    case CREATE_ABSENSE_START:
      return {
        ...state,
        absenseInfo: {
          ...state.absenseInfo,
          isLoading: true,
        },
      }
    case CREATE_ABSENSE_SUCCESS:
      return {
        ...state,
        absenseInfo: {
          success: true,
          isLoading: false,
          data: [...state.absenseInfo.data, action.payload],
          errors: [],
        },
      }
    case CREATE_ABSENSE_FAIL:
      return {
        ...state,
        absenseInfo: {
          ...state.absenseInfo,
          isLoading: false,
          errors: action.payload,
        },
      }
    // UPDATE ABSENSE
    case UPDATE_ABSENSE_START:
      return {
        ...state,
        absenseInfo: {
          ...state.absenseInfo,
          isLoading: true,
        },
      }
    case UPDATE_ABSENSE_SUCCESS:
      return {
        ...state,
        absenseInfo: {
          success: true,
          isLoading: false,
          data: state.absenseInfo.data.map((absense) =>
            absense._id == action.payload._id ? action.payload : absense,
          ),
          errors: [],
        },
      }
    case UPDATE_ABSENSE_FAIL:
      return {
        ...state,
        absenseInfo: {
          ...state.absenseInfo,
          isLoading: false,
          errors: action.payload,
        },
      }

    default:
      return state
  }
}

export default reducer
