import handleErr from 'src/utils/handle-error'
import handleIDB from '../utils/handle-indexed-db'
class TokenService {
  getLocalAccessToken() {
    return new Promise(async (resolve, reject) => {
      try {
        const tokenVal = await handleIDB.getVal({ model: 'Auth', _id: 'XPERDRIVER_TOKEN' })
        // tokenVal.then((token) => resolve(token))
        resolve(tokenVal)
      } catch (err) {
        handleErr({ err })
        reject(null)
      }
    })
  }
}

const tServ = new TokenService()

export default tServ
