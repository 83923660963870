import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './store'
import App from './App'
import LocalServiceWorkerRegister from './sw-register'

const root = createRoot(document.getElementById('root'))

store().then((store) => {
  root.render(
    <Provider store={store}>
      <App />
    </Provider>,
  )
})

LocalServiceWorkerRegister()
