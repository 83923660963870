import React, { useEffect, useRef, useState } from 'react'
import Booking from './Booking'
import FullLoader from 'src/components/FullLoader'
import { defaultBrokerPricing } from 'src/utils/defaultBrokerPricing'
import isEmpty from 'src/utils/is-empty'
import { getBrokersPricingAction } from 'src/actions/brokerPricingActions'
import { useDispatch, useSelector } from 'react-redux'
import handleErr from 'src/utils/handle-error'
import { getCompanyByCodeAction } from 'src/actions/adminActions'

const BookingContainer = () => {
  const dispatch = useDispatch()

  const { brokersPricingList } = useSelector((state) => state?.brokerPricing)
  const { companyInfo } = useSelector((state) => state?.companyAuth)

  const [isLoading, setIsLoading] = useState(true)
  // const [error, setError] = useState('')

  const [brokerPricing, setBrokerPricing] = useState(null)
  const [companyData, setCompanyData] = useState(null)

  const initialPageTitle = useRef(document?.title)

  // const code =
  //   window.location.pathname?.split('/')[window.location.pathname?.split('/')?.length - 1]
  const params = new URLSearchParams(window.location.search)
  const code = params.get('code')

  useEffect(() => {
    try {
      initialPageTitle.current = document?.title
      return () => {
        document.title = initialPageTitle.current
      }
    } catch (err) {
      document.title = initialPageTitle.current
    }
  }, [])

  useEffect(() => {
    try {
      if (code) {
        dispatch(
          getCompanyByCodeAction({
            code,
            cb: ({ company }) => {
              if (!isEmpty(company)) setCompanyData(company)
            },
          }),
        )
      } else if (!isEmpty(companyInfo) && companyInfo?._id) {
        setCompanyData(companyInfo)
      }
    } catch (err) {
      handleErr({ err })
      setIsLoading(false)
    }
  }, [companyInfo, code, dispatch])

  // useEffect(() => {
  //   try {
  //     console.log(code)
  //     if (!isEmpty(companyInfo) || (code && code !== 'booking')) {
  //       // company is a broker also
  //       dispatch(
  //         getCompanyByCodeAction({
  //           code,
  //           cb: ({ company }) => {
  //             if (!isEmpty(company)) {
  //               console.log(company)
  //               setCompanyData(company)
  //               dispatch(
  //                 getBrokersPricingAction({
  //                   company: company?._id,
  //                   broker: company?._id,
  //                   publicReq: true,
  //                   cb: ({ success, brokerPricing }) => {
  //                     setIsLoading(false)
  //                   },
  //                 }),
  //               )
  //             } else {
  //               setIsLoading(false)
  //             }
  //           },
  //         }),
  //       )
  //     } else {
  //       setBrokerPricing(defaultBrokerPricing)
  //       setIsLoading(false)
  //       // setError('Company Id is required to load the booking')
  //     }
  //   } catch (err) {
  //     setBrokerPricing(defaultBrokerPricing)
  //     setIsLoading(false)
  //     handleErr({ err })
  //   }
  // }, [companyInfo, code, dispatch])

  useEffect(() => {
    try {
      if (!isEmpty(companyData) && companyData?._id) {
        document.title = companyData?.name || 'XPERDRIVER'
        setIsLoading(true)
        dispatch(
          getBrokersPricingAction({
            company: companyData?._id,
            broker: companyData?._id,
            publicReq: true,
            cb: ({ success, brokerPricing }) => {
              setIsLoading(false)
            },
          }),
        )
      } else {
        setIsLoading(false)
      }
    } catch (err) {
      handleErr({ err })
      setIsLoading(false)
    }
  }, [companyData, dispatch])

  useEffect(() => {
    try {
      setBrokerPricing(
        brokersPricingList?.data?.length ? brokersPricingList?.data[0] : defaultBrokerPricing,
      )
    } catch (err) {
      setBrokerPricing(defaultBrokerPricing)
      handleErr({ err })
    }
  }, [brokersPricingList])

  if (isLoading) {
    return <FullLoader />
    // } else if (!isLoading && error) {
    //   return <p>{error}</p>
  } else return <Booking brokerPricing={brokerPricing} companyData={companyData} />
}

export default BookingContainer
