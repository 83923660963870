import axios from 'axios'
import tokenService from './tokenService'
import handleErr from 'src/utils/handle-error'
// export const BASE_URL = "";
// import store from '../store'
// axios.defaults.timeout = 3000

export const BASE_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://api.xperdriver.com'

// Production;
export const fetchPrivate = async ({ url, options }) => {
  try {
    return fetch(url, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        accepts: 'application/json',
        Authorization: `Bearer ${await tokenService.getLocalAccessToken()}`,
      },
    })
  } catch (err) {
    handleErr({ err })
  }
}

export const axiosPublic = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    // 'Content-Encoding': 'gzip',
    // 'x-accept-encoding': 'gzip',
    'Content-Type': 'application/json',
    accepts: 'application/json',
  },
})

export const axiosPrivate = async () => {
  try {
    return axios.create({
      baseURL: `${BASE_URL}`,
      headers: {
        // 'Content-Encoding': 'gzip',
        // 'x-accept-encoding': 'gzip',
        'Content-Type': 'application/json',
        accepts: 'application/json',
        Authorization: `Bearer ${await tokenService.getLocalAccessToken()}`,
      },
    })
  } catch (err) {
    handleErr({ err })
  }
}

// Response interceptor for API calls
// axiosPrivate.interceptors.response.use(
//   (response) => {
//     return response
//   },
//   async function (error) {
//     const originalRequest = error.config
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true
//       const token = await tokenService.getLocalAccessToken()
//       // try {
//       //   const { data } = await axiosPrivate.post(`/auth/refresh-tokens`, {
//       //     refreshToken: tokenService.getLocalRefreshToken(),
//       //   })

//       //   // dispatch(refreshToken(accessToken));

//       //   tokenService.updateLocalAccessToken(data)
//       originalRequest.headers['Authorization'] = `Bearer ${token}`
//       axiosPrivate.defaults.headers.Authorization = `Bearer ${token}`

//       return axiosPrivate(originalRequest)
//       // return axiosApiInstance(originalRequest);
//       // } catch (err) {
//       //   return Promise.reject(err)
//       // }
//     }
//     return Promise.reject(error)
//   },
// )

// export default axiosPrivate
