import { io } from 'socket.io-client'
import { BASE_URL } from './api'
// import handleIDB from '../utils/handle-indexed-db'
// import handleErr from 'src/utils/handle-error'

// const getUserInfo = () =>
//   new Promise((resolve) => {
//     try {
//       resolve(handleIDB.getVal({ model: 'Auth', _id: 'userData' }))
//     } catch (err) {
//       console.error(err)
//       resolve({})
//     }
//   })

const socket = io(
  // `https://api.xperdriver.com`,
  BASE_URL,
  {
    transports: ['polling'],
    // perMessageDeflate: false,
    // transports: ['websocket'],
    // transports: ['websocket', 'polling'],
    withCredentials: true,
    // extraHeaders: {
    //   // pooling only
    //   userid: userData._id,
    //   companyid: userData.company?._id,
    // },
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 10000,
    reconnectionAttempts: Infinity,
    // reconnectionAttempts: 1,
  },
)

// const getSocket = () => {
//   try {
//     // const socket = socketIOClient.connect(BASE_URL)
//     return socket
//   } catch (err) {
//     handleErr({ err })
//   }
// }

export default socket
