export default function LocalServiceWorkerRegister() {
  try {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', async () => {
        navigator.serviceWorker
          .register(`${process.env.PUBLIC_URL}/sw.js`)
          .then((registration) => {
            // navigator.serviceWorker.ready
            //   .then((regis) => {
            registration.addEventListener('updatefound', () => {
              // If updatefound is fired, it means that there's
              // a new service worker being installed.
              const installingSW = registration.installing
              // console.log('A new service worker is being installed:', installingSW)
              // You can listen for changes to the installing service worker's
              // state via installingSW.onstatechange
              installingSW.onstatechange = async (e) => {
                console.log(e.target.state)
                // if (e.target.state === 'installed') {
                //   // SW installed.  Refresh page so SW can respond with SW-enabled page.
                //   // window.location.reload()
                //   registration.update()
                // }
                if (e.target.state === 'activated') {
                  console.log(
                    'ServiceWorker registration successful with scope: ',
                    registration.scope,
                  )
                  // registration.update()
                  // window.location.reload()
                }
              }
            })
          })
          .catch((error) => {
            console.error(`Service worker registration failed: ${error}`)
          })
      })
      // })
    } else {
      console.error('Service workers are not supported.')
    }
  } catch (error) {
    console.log(error)
  }
}
