const mobileOS = () => {
  const ua = navigator?.userAgent || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(ua)) {
    return 'Windows Phone'
  }
  if (
    ua?.toLowerCase().indexOf('android') > -1 ||
    /Android|android/i.test(ua) ||
    ua?.match(/Android/i)
  ) {
    return 'Android'
  }
  // if (/android/i.test(ua)) {
  //   return 'Android'
  // }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/i.test(ua) && !window.MSStream) {
    return 'iOS'
  }
  return ''
}

export default mobileOS
