const userReducerInitialState = {
  changedStatusTrip: {
    isLoading: false,
    msg: '',
    data: {},
    errors: [],
  },
  pushedLocTrip: {
    isLoading: false,
    msg: '',
    data: {},
    errors: [],
  },
  resetPwd: {
    isLoading: false,
    msg: '',
    data: {},
    errors: [],
  },
  changePwd: {
    isLoading: false,
    msg: '',
    data: {},
    errors: [],
  },
  quickNotes: {
    loading: false,
    data: [],
    errors: [],
  },
  searchTerms: {},
  // searchTerms: {
  //   constraint: todayRange(),
  //   constraintReport: todayRange(),
  //   productsLogsConstraint: todayRange(),
  //   tripState: 'pending',
  //   driver: '',
  //   customer: '',
  //   destination: '',
  //   includeDeactivated: false,
  //   page: 1,
  // },
  userSettings: {
    globalMap: {
      zoom: 12.888,
      isVisible: false,
      showRoute: true,
      bgLayerVisible: 'isOSM',
    },
  },
}

export default userReducerInitialState
