const vehicleMaxCapacity = {
  sedan: 4,
  minivan: 6,
  van: 8,
  bus: 17,
  wheelchair: 1,
  stretcher: 1,
}

export default vehicleMaxCapacity
