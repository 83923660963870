import {
  USER_RESET_PWD_START,
  USER_RESET_PWD_SUCCESS,
  USER_RESET_PWD_FAIL,
  USER_CHANGE_PWD_START,
  USER_CHANGE_PWD_SUCCESS,
  USER_CHANGE_PWD_FAIL,
  // COMPANY_LOGOUT_SUCCESS,
  // USER_AUTH_FAIL,
  // USER_AUTH_REQUEST,
  // USER_AUTH_SUCCESS,
  // USER_LOGOUT_FAIL,
  // USER_LOGOUT_SUCCESS,
  // GET_USER_FAIL,
  // GET_USER_REQUEST,
  // GET_USER_SUCCESS,
} from '../constants'
import handleErr from '../utils/handle-error'

import { axiosPublic } from '../services/api'
// import axiosPrivate, { axiosPublic } from '../services/api'
// import handleIDB from '../utils/handle-indexed-db'
// import { WatchLocation } from 'src/utils/watchLocation'
// import socket from 'src/services/socket'

//USER REGISTER
// export const userRegister = (obj) => async (dispatch) => {
//   dispatch({
//     type: USER_AUTH_REQUEST,
//   })
//   try {
//     const { data } = await axiosPublic.post(`/auth/register`, obj)
//     handleIDB.put({ model: 'Auth', _id: 'tokens', data: data.tokens })
//     // handleIDB.put('tokens', JSON.stringify(data.tokens))

//     dispatch({
//       type: USER_AUTH_SUCCESS,
//       payload: { ...data, register: true },
//     })
//   } catch (err) {
//     dispatch({
//       type: USER_AUTH_FAIL,
//       payload: err.response.data.message,
//     })
//   }
// }

//USER LOGIN (in companyActions)
// export const userLogin = (obj) => async (dispatch) => {
//   dispatch({
//     type: USER_AUTH_REQUEST,
//   })
//   try {
//     const { data } = await axiosPublic.post(`/auth/login`, obj)
//     handleIDB.put({ model: 'Auth', data: data.tokens, _id: 'tokens' })
//     // localStorage.setItem('tokens', JSON.stringify(data.tokens))
//     dispatch({
//       type: USER_AUTH_SUCCESS,
//       payload: data,
//     })
//   } catch (err) {
//     dispatch({
//       type: USER_AUTH_FAIL,
//       payload: err.response.data.message,
//     })
//   }
// }

//USER LOGOUT (in companyActions)
// export const userLogout = (obj) => async (dispatch) => {
//   dispatch({
//     type: COMPANY_LOGOUT_SUCCESS,
//   })

//   handleIDB.localDB.models.forEach(
//     async (model) => model !== 'Device' && (await handleIDB.deleteAll({ model })),
//   )

//   await store() // clean company data
//   // await WatchLocation({ stopIt: true })
//   socket.disconnect()
//   // handleIDB.delete({ model: 'Auth', _id: 'companyData' })
//   // handleIDB.delete({ model: 'Auth', _id: 'userData' })
//   // handleIDB.delete({ model: 'Auth', _id: 'XPERDRIVER_TOKEN' })
//   // localStorage.removeItem('companyData')
//   // localStorage.removeItem('userData')
//   // localStorage.removeItem('XPERDRIVER_TOKEN')
//   // axiosPrivate.defaults.headers.Authorization = null
// }

// RESET PWD

export const userResetPwd = (payload) => async (dispatch) => {
  dispatch({ type: USER_RESET_PWD_START })
  const { email, cb } = payload
  try {
    const { data } = await axiosPublic.post('/users/request-reset-password', { email })
    const { success, msg } = data
    dispatch({ type: USER_RESET_PWD_SUCCESS, payload: data })
    cb && cb({ success, msg })
  } catch (err) {
    handleErr({
      err,
      dispatch,
      alert: { msg: `Error sending code! Please try again` },
      reduxType: USER_RESET_PWD_FAIL,
    })
    // dispatch({ type: USER_RESET_PWD_FAIL, payload: err.response?.data?.errors })
  }
}

// CHANGE PWD

export const userChangePwd = (payload) => async (dispatch) => {
  dispatch({ type: USER_CHANGE_PWD_START })

  try {
    const { formData, cb } = payload
    const { data } = await axiosPublic.post('/users/reset-password', formData)
    const { success } = data
    cb && cb({ success })
    dispatch({ type: USER_CHANGE_PWD_SUCCESS, payload: data })
  } catch (err) {
    handleErr({
      alert: true,
      err,
      dispatch,
      // alert: { msg: `Error Changing Password!` },
      reduxType: USER_CHANGE_PWD_FAIL,
    })
    // dispatch({ type: USER_CHANGE_PWD_FAIL, payload: err.response?.data?.errors })
  }
}
