import handleErr from './handle-error'

const isCustomer = (user = {}) => {
  try {
    return user?.level === 'Customer'
  } catch (err) {
    handleErr({ err })
    return false
  }
}

const isDriver = (user = {}) => {
  try {
    return user?.level === 'Driver'
  } catch (err) {
    handleErr({ err })
    return false
  }
}

const isOperator = (user = {}) => {
  try {
    return user?.level === 'Operator'
  } catch (err) {
    handleErr({ err })
    return false
  }
}

const isDispatcher = (user = {}) => {
  try {
    return user?.level === 'Dispatcher'
  } catch (err) {
    handleErr({ err })
    return false
  }
}

const isSupervisor = (user = {}) => {
  try {
    return user?.level === 'Supervisor'
  } catch (err) {
    handleErr({ err })
    return false
  }
}

const isAdmin = (user = {}) => {
  try {
    return user?.level === 'Admin'
  } catch (err) {
    handleErr({ err })
    return false
  }
}

const isSuperadmin = (user = {}) => {
  try {
    return user?.level === 'Superadmin'
  } catch (err) {
    handleErr({ err })
    return false
  }
}

export { isCustomer, isDriver, isOperator, isDispatcher, isSupervisor, isAdmin, isSuperadmin }
