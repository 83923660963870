import {
  FIND_HOLIDAY_START,
  FIND_HOLIDAY_SUCCESS,
  FIND_HOLIDAY_FAIL,
  CREATE_HOLIDAY_START,
  CREATE_HOLIDAY_SUCCESS,
  CREATE_HOLIDAY_FAIL,
  UPDATE_HOLIDAY_FAIL,
  UPDATE_HOLIDAY_SUCCESS,
  UPDATE_HOLIDAY_START,
} from '../constants/index'

const initialState = {
  holidayInfo: {
    isLoading: false,
    msg: '',
    data: [],
    errors: [],
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // FIND HOLIDAY
    case FIND_HOLIDAY_START:
      return {
        ...state,
        holidayInfo: {
          ...state.holidayInfo,
          isLoading: true,
        },
      }
    case FIND_HOLIDAY_SUCCESS:
      return {
        ...state,
        holidayInfo: {
          success: true,
          isLoading: false,
          data: action.payload,
          errors: [],
        },
      }
    case FIND_HOLIDAY_FAIL:
      return {
        ...state,
        holidayInfo: {
          ...state.holidayInfo,
          isLoading: false,
          errors: action.payload,
        },
      }
    // FIND HOLIDAY
    case CREATE_HOLIDAY_START:
      return {
        ...state,
        holidayInfo: {
          ...state.holidayInfo,
          isLoading: true,
        },
      }
    case CREATE_HOLIDAY_SUCCESS:
      return {
        ...state,
        holidayInfo: {
          success: true,
          isLoading: false,
          data: [action.payload, ...state.holidayInfo.data],
          errors: [],
        },
      }
    case CREATE_HOLIDAY_FAIL:
      return {
        ...state,
        holidayInfo: {
          ...state.holidayInfo,
          isLoading: false,
          errors: action.payload,
        },
      }

    // UPDATE HOLIDAY
    case UPDATE_HOLIDAY_START:
      return {
        ...state,
        holidayInfo: {
          ...state.holidayInfo,
          isLoading: true,
        },
      }
    case UPDATE_HOLIDAY_SUCCESS:
      return {
        ...state,
        holidayInfo: {
          success: true,
          isLoading: false,
          data: action.payload.isDelete
            ? state.holidayInfo.data.filter((holiday) => holiday._id !== action.payload._id)
            : state.holidayInfo.data.map((holiday) =>
                holiday._id == action.payload._id ? action.payload : holiday,
              ),
          errors: [],
        },
      }
    case UPDATE_HOLIDAY_FAIL:
      return {
        ...state,
        holidayInfo: {
          ...state.holidayInfo,
          isLoading: false,
          errors: action.payload,
        },
      }

    default:
      return state
  }
}

export default reducer
