const formatPhone = (phone) => {
  const num = phone?.replace(/\s/g, '').replace(/[^0-9]/g, '')
  // const num = phone?.replace(/\D/g, '').replace(/\s/g, '')
  return (
    // num.substring(0, 1) +
    // num.substring(0, 3) + '-' + num.substring(3, 6) + '-' + num.substring(6, 10)
    num?.substring(0, 10)?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  )
  //   return (
  //     num.substring(0, 1) +
  //     '(' +
  //     num.substring(1, 4) +
  //     ')' +
  //     num.substring(4, 7) +
  //     '-' +
  //     num.substring(7, 11)
  //   )
}

export default formatPhone
