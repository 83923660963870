const Capitalize = (words) =>
  words && words.length
    ? words
        .trim()
        .split(' ')
        .map((word) =>
          word && word[0] ? word[0].toUpperCase() + word.substring(1).toLowerCase() : word,
        )
        .join(' ')
    : ''

export default Capitalize
