import { todayRange } from './handle-date'

export const initiaSearchTerms = {
  constraint: todayRange(),
  constraintReport: todayRange(),
  productsLogsConstraint: todayRange(),
  tripState: 'pending',
  driver: '',
  customer: '',
  destination: '',
  includeDeactivated: false,
  page: 1,
}
