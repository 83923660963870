import { uniqueById } from 'src/utils/helpers'
import {
  GET_REMINDERS_START,
  GET_REMINDERS_SUCCESS,
  GET_REMINDERS_FAIL,
  CREATE_REMINDER_RESET,
  CREATE_REMINDER_START,
  CREATE_REMINDER_SUCCESS,
  CREATE_REMINDER_FAIL,
  UPDATE_REMINDER_RESET,
  UPDATE_REMINDER_START,
  UPDATE_REMINDER_SUCCESS,
  UPDATE_REMINDER_FAIL,
  DEACTIVATE_REMINDER_START,
  DEACTIVATE_REMINDER_SUCCESS,
  DEACTIVATE_REMINDER_FAIL,
  DELETE_REMINDER_START,
  DELETE_REMINDER_SUCCESS,
  DELETE_REMINDER_FAIL,
} from '../constants/index'

const initialState = {
  remindersList: {
    isLoading: false,
    data: [], // reminders,
    errors: [],
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // GET REMINDERS

    case GET_REMINDERS_START:
      return {
        ...state,
        remindersList: {
          ...state.remindersList,
          msg: 'Loading! Please wait...',
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case GET_REMINDERS_SUCCESS:
      return {
        ...state,
        remindersList: {
          data: uniqueById(action?.payload),
          success: true,
          isLoading: false,
          errors: [],
        },
      }
    case GET_REMINDERS_FAIL:
      return {
        ...state,
        remindersList: {
          ...state.remindersList,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // CREATE REMINDER

    case CREATE_REMINDER_RESET:
      return {
        ...state,
        createdReminder: {
          isLoading: false,
          errors: [],
          data: {},
          msg: '',
        },
      }
    case CREATE_REMINDER_START:
      return {
        ...state,
        createdReminder: {
          ...state.createdReminder,
          msg: 'Creating ...',
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case CREATE_REMINDER_SUCCESS:
      const remindersData = state.remindersList?.data?.filter(
        (b) => b?._id !== action?.payload?.reminder?._id,
      )
      return {
        ...state,
        createdReminder: {
          ...action.payload,
          success: true,
          isLoading: false,
        },
        remindersList: {
          ...state.remindersList,
          data: [...remindersData, action?.payload?.reminder],
          success: true,
          isLoading: false,
        },
      }
    case CREATE_REMINDER_FAIL:
      return {
        ...state,
        remindersList: {
          ...state.remindersList,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // UPDATE REMINDER

    case UPDATE_REMINDER_RESET:
      return {
        ...state,
        updatedReminder: {
          msg: '',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
        deactivatedReminder: {
          msg: '',
          data: {},
          success: false,
          isLoading: false,
          errors: [],
        },
      }
    case UPDATE_REMINDER_START:
      return {
        ...state,
        updatedReminder: {
          msg: 'Updating! Please wait ...',
          data: {},
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case UPDATE_REMINDER_SUCCESS:
      const filteredReminders = state.remindersList.data.filter(
        (d) => d?._id !== action?.payload?.reminder?._id,
      )
      return {
        ...state,
        updatedReminder: {
          ...action?.payload,
          success: true,
          isLoading: false,
        },
        remindersList: {
          data: [...filteredReminders, action?.payload?.reminder],
          success: true,
          isLoading: false,
        },
      }
    case UPDATE_REMINDER_FAIL:
      return {
        ...state,
        updatedReminder: {
          ...state.updatedReminder,
          isLoading: false,
          errors: action?.payload,
        },
      }

    // DEACTIVATE REMINDER

    case DEACTIVATE_REMINDER_START:
      return {
        ...state,
        deactivatedReminder: {
          success: false,
          isLoading: true,
          errors: [],
          msg: 'Deactivating destination...',
        },
      }
    case DEACTIVATE_REMINDER_SUCCESS:
      const activeReminders = state.remindersList.data.filter(
        (d) => d._id !== action?.payload?.reminder?._id,
      )
      return {
        ...state,
        deactivatedReminder: {
          ...action.payload,
          success: true,
          isLoading: false,
        },
        remindersList: {
          data: [...activeReminders, action.payload.reminder],
          success: true,
          isLoading: false,
        },
      }
    case DEACTIVATE_REMINDER_FAIL:
      return {
        ...state,
        deactivatedReminder: { ...state.payload, isLoading: false, errors: action?.payload },
      }

    // DELETE REMINDER

    case DELETE_REMINDER_START:
      return {
        ...state,
        deletedReminder: {
          msg: 'Deleting! Please wait...',
          success: false,
          isLoading: true,
          errors: [],
        },
      }
    case DELETE_REMINDER_SUCCESS:
      const remainingReminders = state.remindersList.data.filter(
        (d) => d._id !== action?.payload?.reminder?._id,
      )
      return {
        ...state,
        deletedReminder: {
          ...action?.payload,
          success: true,
          isLoading: false,
        },
        remindersList: {
          data: remainingReminders,
          success: true,
          isLoading: false,
        },
      }
    case DELETE_REMINDER_FAIL:
      return {
        ...state,
        deletedReminder: {
          ...state.deletedReminder,
          isLoading: false,
          errors: action?.payload,
        },
      }

    default:
      return state
  }
}

export default reducer
