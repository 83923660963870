import {
  COMPANY_LOGIN_FAIL,
  COMPANY_LOGIN_REQUEST,
  COMPANY_LOGIN_SUCCESS,
  COMPANY_LOGOUT_SUCCESS,
  OTP_RESEND_FAIL,
  OTP_RESEND_REQUEST,
  OTP_RESEND_SUCCESS,
  OTP_SUCCESS,
  OTP_VERIFY_FAIL,
  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCCESS,
  COMPANY_REGISTER_CLEAN,
  COMPANY_REGISTER_FAIL,
  COMPANY_REGISTER_REQUEST,
  COMPANY_REGISTER_SUCCESS,
  SAVE_DEVICE_DETAILS,
} from '../constants'

export const companyRegisterReducer = (
  state = {
    auth: {
      companyInfo: null,
      tokens: null,
      loading: false,
    },
  },
  action,
) => {
  switch (action.type) {
    //VENDOR AUTH
    case COMPANY_REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        error: action.payload,
      }
    case COMPANY_REGISTER_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        companyInfo: action.payload.company,
        userInfo: { ...action.payload, company: undefined },

        token: action.payload.token,
      }
    case COMPANY_REGISTER_FAIL:
      return {
        ...state,
        loading: false,
        companyInfo: null,
        errors: action?.payload,
      }
    case COMPANY_REGISTER_CLEAN:
      return {
        ...state,
        loading: false,
        companyInfo: null,
        errors: [],
      }
    default:
      return state
  }
}

export const companyAuthReducer = (
  state = {
    auth: {
      companyInfo: null,
      tokens: null,
      loading: false,
    },
  },
  action,
) => {
  switch (action.type) {
    //VENDOR AUTH
    case COMPANY_LOGIN_REQUEST:
      return {
        loading: true,
      }
    case COMPANY_LOGIN_SUCCESS:
      const { user, token } = action?.payload || {}
      const userInfo = JSON.parse(JSON.stringify(user))
      userInfo.company = { _id: user?.company?._id }
      return {
        ...state,
        success: true,
        loading: false,
        companyInfo: user.company || user,
        userInfo,
        token,
      }
    case OTP_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        otpSent: true,
      }
    case COMPANY_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case OTP_VERIFY_REQUEST:
      // axiosPrivate.defaults.headers.Authorization = 'Bearer ' + action.payload.token
      return {
        ...state,
        success: false,
        verifyOtpLoading: true,
        otpError: null,
      }

    case OTP_VERIFY_SUCCESS:
      // axiosPrivate.defaults.headers.Authorization = 'Bearer ' + action.payload.token
      return {
        ...state,
        success: false,
        verifyOtpLoading: false,
        otpSent: false,
        otpError: null,
      }
    case OTP_VERIFY_FAIL:
      return {
        ...state,
        success: false,
        verifyOtpLoading: false,
        otpSent: false,
        otpError: action.payload?.error || action.payload?.message || action.payload,
      }

    case OTP_RESEND_REQUEST:
      // axiosPrivate.defaults.headers.Authorization = 'Bearer ' + action.payload.token
      return {
        ...state,
        success: false,
        resendOtpLoading: false,
        otpError: null,
      }

    case OTP_RESEND_SUCCESS:
      // axiosPrivate.defaults.headers.Authorization = 'Bearer ' + action.payload.token
      return {
        ...state,
        success: false,
        resendOtpLoading: false,
        otpSent: true,
      }
    case OTP_RESEND_FAIL:
      // axiosPrivate.defaults.headers.Authorization = 'Bearer ' + action.payload.token
      return {
        ...state,
        success: false,
        resendOtpLoading: false,
        otpSent: false,
        otpError: action.payload,
      }
    case SAVE_DEVICE_DETAILS:
      return {
        ...state,
        deviceDetails: action.payload,
      }
    case COMPANY_LOGOUT_SUCCESS:
      return {
        companyInfo: null,
        userInfo: null,
      }
    default:
      return state
  }
}
