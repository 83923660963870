import handleErr from './handle-error'
import mobileOS from './iOS'

export const dragger = ({ handle, el, offSet }) => {
  try {
    // var pos1 = 0,
    //   pos2 = 0,
    //   pos3 = 0,
    //   pos4 = 0
    // if (document.getElementById(elmnt.id + 'header')) {
    //   /* if present, the header is where you move the DIV from:*/
    //   document.getElementById(elmnt.id + 'header').onmousedown = dragMouseDown
    // } else {
    /* otherwise, move the DIV from anywhere inside the DIV:*/
    if (mobileOS()) {
      handle.addEventListener('touchmove', (e) => {
        // grab the location of touch
        if (e?.targetTouches?.length) {
          const touchLocation = e?.targetTouches[0]

          // assign box new coordinates based on the touch.
          // el.style.top = touchLocation.pageY + 'px'
          el.style.position = 'fixed'
          el.style.top = `${Math.round(touchLocation.clientY - handle.offsetHeight)}px`
          // el.style.left = touchLocation.pageX + 'px'
          el.style.left = `${Math.round(touchLocation.clientX - handle.offsetWidth / 2)}px`
        }
      })

      handle.addEventListener('touchend', (e) => {
        // current box position.
        // var x = parseInt(el.style.left)
        // var y = parseInt(el.style.top)
      })
      return
    }

    handle.onmousedown = dragMouseDown
    // }

    function dragMouseDown(e) {
      e = e || window.event
      e.preventDefault()
      // get the mouse cursor position at startup:
      // pos3 = e.clientX
      // pos4 = e.clientY
      document.onmouseup = closeDragElement
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag
    }

    function elementDrag(e) {
      if (!e) return false
      e.preventDefault()
      // el.style.marginTop = 0
      // el.style.marginLeft = 0
      el.style.position = 'fixed'
      // calculate the new cursor position:
      // pos1 = pos3 - e.clientX
      // pos2 = pos4 - e.clientY
      // pos3 = e.clientX
      // pos4 = e.clientY
      // set the element's new position:
      console.log(handle.offsetHeight)
      el.style.top = `${Math.round(e.clientY - handle.offsetHeight / 2)}px` // elmnt.offsetTop - pos2 + 'px'
      // el.style.top = `${e.clientY - (offSet.y || 0)}px` // elmnt.offsetTop - pos2 + 'px'
      el.style.left = `${Math.round(e.clientX - handle.offsetWidth / 2)}px` //elmnt.offsetLeft - e.clientX + 'px' //elmnt.offsetLeft - pos1 + 'px'
      // el.style.left = `${e.clientX - (offSet.x - 44 || 0)}px` //elmnt.offsetLeft - e.clientX + 'px' //elmnt.offsetLeft - pos1 + 'px'
    }

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null
      document.onmousemove = null
    }
  } catch (err) {
    handleErr({ err })
  }
}
